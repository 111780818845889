import { gsap as __gsap } from 'gsap'
import type { Ref } from 'vue'

export type TUseGSAP = {
  // @ts-ignore
  context: __gsap.Context
  contextSafe: Function
}

export const useGSAP = (
  callback: Function | null,
  scope: Ref<any>,
): TUseGSAP => {
  // console.log('useGSAP init', callback)
  const { $gsap } = useNuxtApp()
  const context = $gsap.context(() => {}, scope.value)

  const contextSafe = (cb: Function) => {
    context.add(cb, scope.value)
  }

  onMounted(() => {
    // console.log('useGSAP mounted')
    callback && context.add(callback, scope.value)
  })

  onUnmounted(() => {
    // console.log('useGSAP unmounted')
    context.revert()
  })

  return { context, contextSafe }
}
